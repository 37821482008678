<template>
  <div class="dashboard" v-if="!loading">
    <v-row>
      <v-col cols="12">
        <v-card color="#000528" height="150" v-if="!$vuetify.theme.isDark">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-img
              :src="
                !$vuetify.theme.isDark
                  ? require('@/views/Login/assets/white-logo.svg')
                  : require('@/views/Login/assets/dark-logo.svg')
              "
              :max-width="$vuetify.breakpoint.mdAndUp ? 600 : 300"
            ></v-img>
          </v-row>
        </v-card>
        <v-card color="" height="150" v-else>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-img
              :src="
                !$vuetify.theme.isDark
                  ? require('@/views/Login/assets/white-logo.svg')
                  : require('@/views/Login/assets/dark-logo.svg')
              "
              max-width="600"
            ></v-img>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column align-center justify-center"
      >
        <v-card v-if="this.picture_url" class="pa-4">
          <v-img
            :src="`${bucketUrl}${this.picture_url}`"
            max-width="150"
            max-height="150"
            :alt="this.name"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        <!-- <img src="./assets/test.png"></img> -->
        <!-- 400px es idea logotipo es decir horizontal -->
        <h2>{{ this.name }}</h2>
        <h2 v-if="is_corporate_master" class="warning--text">
          Panel corporativo master
        </h2>
        <h4>PANEL ID: {{ this.unique_id }}</h4>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column align-center justify-center"
      >
        <a
          href="https://api.whatsapp.com/send?phone=584126509977"
          target="_blank"
          ><v-img src="@/assets/banner-numero.png" max-width="300" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template> </v-img
        ></a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex flex-column align-center">
        <h2 class="">
          {{ $t(salute) }}
          <span>
            <span v-if="salute === 'Good Morning!'"> ☀️</span>
            <span v-if="salute === 'Good Afternoon!'"> 🌇</span>
            <span v-if="salute === 'Good Evening!'"> 🌃</span>
          </span>
        </h2>
        <h2 class="">{{ $t("Welcome again") }}</h2>
      </v-col>
      <v-col
        cols="12"
        class="
          fontUrbanist
          d-flex
          flex-row
          align-center
          justify-center
          text-center
        "
      >
        {{ timeNow | moment("DD-MM-YYYY h:mm:ss a") }}
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      apiTest: null,
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      timeNow: null,
      salute: "",
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    async testAPI() {
      this.setLoading(true);
      try {
        const { data } = await axios.post("/welcome");
        !!data && (this.apiTest = data);
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    getNow() {
      const today = new Date();
      let hr = today.getHours();
      if (hr >= 6 && hr < 12) {
        this.salute = "Good Morning!";
      } else if (hr >= 12 && hr <= 19) {
        this.salute = "Good Afternoon!";
      } else {
        this.salute = "Good Evening!";
      }
      this.timeNow = today;
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", [
      "name",
      "picture_url",
      "unique_id",
      "is_corporate_master",
    ]),
    timeNowComputed() {
      return this.timeNow;
    },
  },
  created() {
    this.testAPI();
    setInterval(this.getNow, 1000);
  },
};
</script>
<style lang="scss">
.dashboard {
  &-banner-container {
    width: 100%;
    height: 320px;
    &-image {
      border-radius: 10px;
    }
  }
}

@import "~vuetify/src/styles/styles.sass";
.triangle-card {
  position: relative;
  .triangle-bg {
    position: absolute;
    bottom: 0;
    @include ltr() {
      right: 0;
    }
    @include rtl() {
      left: 0;
    }
  }
  .gamification-trophy {
    position: absolute;
    bottom: 10%;
    @include ltr() {
      right: 8%;
    }
    @include rtl() {
      left: 8%;
    }
  }
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  @include ltr() {
    right: 0;
  }
  @include rtl() {
    left: 0;
  }
}
.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 15%;
  }
  @include rtl() {
    left: 15%;
  }
}
.gamification-tree-4 {
  bottom: 0;
  @include ltr() {
    right: 30%;
  }
  @include rtl() {
    left: 35%;
  }
}
@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    @include ltr() {
      right: 5%;
    }
    @include rtl() {
      left: 5%;
    }
  }
}
@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}
@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
